import { gql } from '@apollo/client';
import { textPairFragment, fieldOptionListValueFragment } from '../../../query/fragments';

export const EMAIL_US_MODULE_QUERY = gql`
  query EmailUsModuleQuery {
    textFor {
      emailUsLabelText: pair(pairKey: "emailUsLabel") { ...textPair }
    }
  }
  ${textPairFragment}
`;

export const EMAIL_US_MODAL_TEXT_QUERY = gql`
  query EmailUsModalText {
    textFor {
      modalHeading: pair(pairKey: "emailUsModalHeading") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const EMAIL_US_FORM_TEXT_QUERY = gql`
  query EmailUsFormText {
    textFor {
      formFieldName: textField(fieldKey: "emailUsFormName") {
        label { ...textPair }
        error { ...textPair }
      }
      formFieldEmail: textField(fieldKey: "emailUsFormEmail") {
        label { ...textPair }
        error { ...textPair }
      }
      formFieldOrderNum: pair(pairKey: "emailUsFormOrderNum") { ...textPair }
      formFieldDropDown: listField(fieldKey: "emailUsFormDropDown") {
        ...fieldOptionListValues
      }
      formFieldComments: textField(fieldKey: "emailUsFormComments") {
        label { ...textPair }
        error { ...textPair }
      }
      modalHeading: pair(pairKey: "emailUsModalHeading") { ...textPair }
      formReasons: pair(pairKey: "emailUsFormReasons") { ...textPair }
      formResponseNote: pair(pairKey: "emailUsFormResponseNote") { ...textPair }
      formLegal: pair(pairKey: "emailUsFormLegal") { ...textPair }
      formError: pair(pairKey: "formError") { ...textPair }
      formDisputeLabel: pair(pairKey: "emailUsFormDisputeLabel") { ...textPair }
      buttonProcessing: pair(pairKey: "processing") { ...textPair }
      buttonSubmit: pair(pairKey: "emailUsButtonSubmit") { ...textPair }
      buttonSuccess: pair(pairKey: "emailUsButtonSuccess") { ...textPair }
    }
    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
  }

  ${fieldOptionListValueFragment}
  ${textPairFragment}
`;

export const SEND_EMAIL_MUTATION = gql`
  mutation SendEmail($emailForm: EmailForm!) {
    sendEmail(emailForm: $emailForm) { id }
  }
`;
