import { gql } from '@apollo/client';
import { textPairFragment, apiResponseFragment, paymentFragment } from '../../query/fragments';

export const paymentModuleQuery = gql`
query PaymentModuleQuery {
  textFor {
    addPaymentText: pair(pairKey: "addPaymentText") {...textPair }
    buttonAddNew: pair(pairKey: "paymentsPageAddNew") { ...textPair }
    buttonEdit: pair(pairKey: "editButton") { ...textPair }
    buttonMakeDefault: pair(pairKey: "paymentCardMakeDefault") { ...textPair }
    cardAriaEndingIn: pair(pairKey: "paymentCardAriaEndingIn") { ...textPair }
    cardDefaultText: pair(pairKey: "paymentCardDefault") { ...textPair }
    cardTitle: pair(pairKey: "paymentCardTitle") { ...textPair }
    formError: pair(pairKey: "formError") {...textPair }
    noPayments: pair(pairKey: "paymentsPageEmpty") { ...textPair }
    pageTitle: pair(pairKey: "paymentsPageTitle") { ...textPair }
    paymentApiError: pair(pairKey: "paymentApiError") {...textPair }
    paymentModalLegalText: pair(pairKey: "paymentModalLegalText") {...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") {...textPair }
    processing: pair(pairKey: "processing") {...textPair }
    removePaymentText: pair(pairKey: "removePaymentText") {...textPair }
    submitPaymentError: pair(pairKey: "submitPaymentError") {...textPair }
    updatePaymentText: pair(pairKey: "updatePaymentText") {...textPair }
    success: pair(pairKey: "success") {...textPair }
    paymentLimitError: pair(pairKey: "paymentLimitError") {...textPair }
  }
  isMFELegalModalEnabled: featureFlag(
    key: "has-mfe-legal-modals-enabled",
    defaultValue: false
  )
  config {
    pxpModulus: string(name: "pxpModulus")
    pxpExponent: string(name: "pxpExponent")
  }
}
${textPairFragment}
`;

export const getPaymentQuery = gql`
  query GetPayment($id: ID!) {
    getPayment(id: $id) {...payment}
  }

  ${paymentFragment}
`;

export const getAllPaymentsQuery = gql`
  query GetAllPayments {
    getAllPayments {
      default
      payments {...payment}
    }
  }

  ${paymentFragment}
`;

export const addPaymentRequest = gql`
  mutation AddPayment($card: Card!) {
    addPayment(card: $card) {
      id
      success
    }
  }
`;

export const updatePaymentRequest = gql`
  mutation UpdatePayment($expiration: Expiration!, $id: ID!) {
    updatePayment(expiration: $expiration, id: $id) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

export const deletePaymentRequest = gql`
  mutation DeletePayment($id: ID!) {
    deletePayment(id: $id) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

export const setDefaultPaymentRequest = gql`
  mutation SetDefaultPayment($id: ID!) {
    setDefaultPayment(id: $id) {
      ...response
    }
  }

  ${apiResponseFragment}
`;
