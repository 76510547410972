import React, { useState, useRef } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  ErrorMessage,
  FieldGroup,
  LegalText,
  TooltipWidget,
} from 'anf-core-react';
import { useController } from 'react-hook-form';
import * as FormStructure from '../FormStructure';
import { TmntText, TmntHtml } from '../Common/Text';
import PhoneNumberField from '../Common/PhoneNumber/PhoneNumberField';
import ControlledInputField from '../Common/ControlledInputField/ControlledInputField';
import { useCheckboxGroup, useLegalLinkEvents } from '../../hooks';
import { StepTwoPropTypes, StepTwoDefaultProps } from './props';
import LegalButton from '../Common/LegalModalBlock/LegalButton';
import { ModalContextProvider } from '../../context/ModalContext';
import LegalModal from '../Common/LegalModalBlock/LegalModal';

export default function StepTwo({
  control,
  queryData,
  onMarketingBrandsChange,
}) {
  const [isTooltipOpen, setIsToolTipOpen] = useState(false);

  const { textFor, config, isMFELegalModalEnabled } = queryData;

  const { label: legendLabel, formGroupList } = textFor.joinForm;
  const [, personalInformationGroup = [], marketingBrandsGroup = []] = formGroupList;
  const { check: joinLegalCheck, text: joinLegalText } = textFor.joinLegal;
  const { joinFormPrivacyTerms } = textFor;

  const [
    marketingBrands,,
    updateMarketingBrands,
    isMarketingBrandChecked,
  ] = useCheckboxGroup(config?.autoSelectedMarketingBrands);

  const { field: legalField, fieldState: legalFieldState } = useController({
    name: 'legal-check',
    control,
    defaultValue: false,
    rules: { required: joinLegalCheck },
  });

  const { field: marketingBrandsField } = useController({
    name: 'marketing-brands[]',
    control,
    defaultValue: marketingBrands,
  });

  // capture legal text key from TMNT
  const legalLinkRef = useRef(null);
  const [legalTmntKey, setLegalTmntKey] = useState(null);

  useLegalLinkEvents(legalLinkRef, (value) => {
    if (value) {
      setLegalTmntKey(value);
    }
  });

  const handleMarketingBrandsChange = (event) => {
    const { value } = event.target;
    const brands = updateMarketingBrands(event.target);
    marketingBrandsField.onChange(brands);
    if (onMarketingBrandsChange) onMarketingBrandsChange(value);
  };

  const renderLegalText = (text) => (
    isMFELegalModalEnabled ? (
      <>
        <LegalButton
          text={text}
          ref={legalLinkRef}
        />
        {legalTmntKey && (
          <ModalContextProvider>
            <LegalModal
              legalText={legalTmntKey}
            />
          </ModalContextProvider>
        )}
      </>
    ) : (
      <LegalText>
        <TmntHtml tmnt={text} />
      </LegalText>
    )
  );

  const renderPersonalInformation = (field) => {
    const { name, label, error } = field;
    const id = `join-now-${name}`;
    switch (name) {
      case 'first-name':
        return (
          <ControlledInputField
            autoComplete="name"
            id={id}
            key={id}
            label={label?.value}
            name={name}
            control={control}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </ControlledInputField>
        );
      case 'last-name':
        return (
          <ControlledInputField
            autoComplete="name"
            id={id}
            key={id}
            label={label?.value}
            name={name}
            control={control}
          >
            <ErrorMessage>
              <TmntText tmnt={error} />
            </ErrorMessage>
          </ControlledInputField>
        );
      default:
        return null;
    }
  };

  const renderCheckboxGroup = (group = []) => {
    if (!group.length) return null;

    const { label, description, optionList } = group[0];

    const renderCheckboxDescription = ({ desc, title }) => (
      <>
        {/* TODO: Will require SCSS to display: block the bottom span for the legal text */}
        <TmntHtml tmnt={title} />
        { desc && <TmntHtml tmnt={desc} /> }
      </>
    );

    const checkboxes = optionList.map((item) => {
      const id = `signInMarketingPreferencesCheckbox_${item.optionValue}`;

      return (
        <Checkbox
          description={renderCheckboxDescription({
            desc: item?.description,
            title: item.text,
          })}
          key={id}
          id={id}
          label={item.text?.value}
          isChecked={isMarketingBrandChecked(item.optionValue)}
          onChange={handleMarketingBrandsChange}
          name={marketingBrandsField.name}
          value={item.optionValue}
        />
      );
    });

    return (
      <CheckboxGroup title={label?.value}>
        <span className={description?.key}>
          {description?.value}
        </span>
        { checkboxes }
      </CheckboxGroup>
    );
  };

  const renderSecondaryGroup = (fields) => fields?.map((field) => renderPersonalInformation(field));

  const renderPhoneField = (fields) => {
    const phoneField = fields[2];

    const renderPhoneLabel = (label, description) => {
      if (!label) return null;
      if (!description) return null;
      return `${label?.value} (${description?.value})`;
    };

    const handleOnClick = () => setIsToolTipOpen(!isTooltipOpen);

    const handleOnKepUp = (event) => {
      event.stopPropagation();
      setIsToolTipOpen(event.key !== 'Escape');
    };

    if (!phoneField) return null;

    const {
      name, label, description, error,
    } = phoneField;

    return (
      <div className="join-now-phone-number-input-field">
        <PhoneNumberField
          control={control}
          id={`join-now-${name}`}
          label={renderPhoneLabel(label, description)}
          name={name}
        >
          <TooltipWidget
            buttonLabelText={textFor?.joinNowPhoneNumberTooltipLabel.value}
            contentPosition="top-left"
            contentID="keep-me-signed-in-tooltip"
            isOpen={isTooltipOpen}
            onClick={handleOnClick}
            onKeyUp={handleOnKepUp}
          >
            <TmntText tmnt={textFor?.joinNowTooltipWhyPhoneNumberText} />
            <TmntText tmnt={textFor?.joinNowTooltipCantRememberText} />
            <TmntText tmnt={textFor?.joinNowTooltipMoreQuestionsText} />
            <span>
              { config?.toolTipCustomerServicePhoneNumber}
            </span>
          </TooltipWidget>
          <ErrorMessage>
            <TmntText tmnt={error} />
          </ErrorMessage>
        </PhoneNumberField>
      </div>
    );
  };

  return (
    <>
      <FormStructure.FormCell align="center">
        <div className="h3">
          <TmntText tmnt={textFor?.almostDone} />
        </div>
      </FormStructure.FormCell>
      <FormStructure.FormCell>
        <FieldGroup legend={legendLabel?.value}>
          { renderSecondaryGroup(personalInformationGroup.fieldList) }
        </FieldGroup>
      </FormStructure.FormCell>
      <FormStructure.FormCell>
        { renderPhoneField(personalInformationGroup.fieldList) }
      </FormStructure.FormCell>
      <FormStructure.FormCell>
        { renderCheckboxGroup(marketingBrandsGroup.fieldList) }
      </FormStructure.FormCell>
      <FormStructure.FormCell>
        <hr />
      </FormStructure.FormCell>
      { joinFormPrivacyTerms && (
        <FormStructure.FormCell>
          {renderLegalText(joinFormPrivacyTerms.text)}
        </FormStructure.FormCell>
      )}
      <FormStructure.FormCell>
        { joinLegalCheck ? (
          <Checkbox
            description={renderLegalText(joinLegalText)}
            id="join-form-legal-check"
            isChecked={legalField.value}
            isInvalid={legalFieldState.invalid}
            isRequired
            name={legalField.name}
            onChange={legalField.onChange}
            value="y"
          />
        ) : renderLegalText(joinLegalText)}
      </FormStructure.FormCell>
    </>
  );
}

StepTwo.propTypes = StepTwoPropTypes;
StepTwo.defaultProps = StepTwoDefaultProps;
