import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client';
import { useCheckboxGroup } from '../../hooks';
import MarketingPreferencesForm from './MarketingPreferencesForm';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import {
  apiResponseFragment,
  fieldOptionListValueFragment,
  sectionedFieldOptionListValuesFragment,
  textPairFragment,
} from '../../query/fragments';
import validateErrors from '../../tools/unauthorizedUser/validateErrors';
import { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';

const marketingPrefsFormTextQuery = gql`
  query MarketingPreferencesText {
    textFor {
      cancel: pair(pairKey: "cancel") {...textPair}
      legalLinks: pair(pairKey: "marketingPrefsLegalLinks") {...textPair}
      marketingPrefsPreloadError: pair(pairKey: "marketingPrefsPreloadError") {...textPair}
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") {...textPair}      
      processing: pair(pairKey: "processing") {...textPair}
      save: pair(pairKey: "save") {...textPair}      
      success: pair(pairKey: "success") {...textPair}
      legalDisclaimer: pair(pairKey: "subscribeLegalDisclaimer") {...textPair}
      marketingPreferencesCheckboxes: listFieldGroup(fieldKey: "marketingPreferencesCheckboxes") {
        ... on FieldOptionList { ...fieldOptionListValues }
        ... on SectionedFieldOptionList { ...sectionedFieldOptionListValues }
      }
    }

    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
  }

  ${textPairFragment}
  ${fieldOptionListValueFragment}
  ${sectionedFieldOptionListValuesFragment}
`;

const preloadPreferencesQuery = gql`
  query userEmailPreferences($email: String!) {
    preSelectedCheckboxes: userEmailPreferences(email: $email)
  }
`;

const marketingEmailPrefsRequest = gql`
  mutation updateEmailPrefsRequest(
    $email: String!,
    $emailOptions: [BrandSelection]!,
    $originatingSection: String!,
  ) {
    updateEmailPrefsReq: updateEmailPrefsRequest(
      email: $email,
      emailOptions: $emailOptions,
      originatingSection: $originatingSection,
    ) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

let textCache;

export default function MarketingPreferencesFormContainer({ email }) {
  const formRef = useRef(null);
  const [
    selectedCheckboxes,
    setSelectedCheckboxes,
    updateCheckboxList,
    isSelectedCheckbox,
  ] = useCheckboxGroup([]);
  const [isErrorState, setIsErrorState] = useState(false);
  const [formError, setFormError] = useState(null);
  const [initialCheckboxState, setInitialCheckboxState] = useState([]);
  const [handleUnsuccessfulSubmit, setHandleUnsuccessfulSubmit] = useState(undefined);

  const isEdited = () => (
    JSON.stringify(initialCheckboxState) !== JSON.stringify(selectedCheckboxes)
  );

  const analyticsData = {
    eventName: 'universal_click',
    eventData: {
      event_name: 'click',
      data_text: 'email_opt_in',
      data_action: 'click',
      email_opt_in: true,
    },
  };

  const trackSuccessfulMarketingBrands = useCustomTealiumTrigger(analyticsData);

  const [updateMarketingPrefs] = useMutation(marketingEmailPrefsRequest, {
    onCompleted: () => { setInitialCheckboxState(selectedCheckboxes); },
    onError: ({ graphQLErrors }) => {
      if (!validateErrors(graphQLErrors)) {
        setHandleUnsuccessfulSubmit(() => window.location.reload());
      }
    },
  });

  const {
    loading,
    error: textQueryError,
    data: textQueryData,
  } = useQuery(marketingPrefsFormTextQuery);

  /* Pre-load checkboxes */
  useQuery(preloadPreferencesQuery, {
    context: { batch: true },
    variables: { email },
    onCompleted: ({ preSelectedCheckboxes }) => {
      setInitialCheckboxState(preSelectedCheckboxes);
      setSelectedCheckboxes(preSelectedCheckboxes);
    },
    onError: () => {
      setFormError(textCache?.marketingPrefsPreloadError);
      setIsErrorState(true);
    },
  });

  if (loading) return LOADING_MESSAGE;
  if (textQueryError) return ERROR_MESSAGE;

  const {
    cancel,
    legalLinks,
    pleaseTryAgain,
    processing,
    save,
    success,
    marketingPreferencesCheckboxes,
    legalDisclaimer,
  } = textQueryData.textFor;

  textCache = textQueryData.textFor;

  const { isMFELegalModalEnabled } = textQueryData;

  /* Member functions */
  const handleOnCancel = () => {
    setSelectedCheckboxes(initialCheckboxState);
    setIsErrorState(false);
  };

  const handleOnSubmit = async () => {
    const form = formRef.current;
    const emailOptions = [...form.querySelectorAll('input[type="checkbox"]')]
      .map((checkboxEl) => ({ key: checkboxEl.value, value: checkboxEl.checked }));
    const userMarketingFlag = String(emailOptions.some((brandOpt) => brandOpt.value === true));

    analyticsData.eventData = {
      ...analyticsData.eventData,
      user_marketing_flag: userMarketingFlag,
    };

    trackSuccessfulMarketingBrands();
    setIsErrorState(false);
    setFormError(textCache.marketingPrefsFormError);
    setHandleUnsuccessfulSubmit(undefined);

    return updateMarketingPrefs({
      variables: {
        email,
        emailOptions,
        originatingSection: 'MyAccount',
      },
    })
      .then(({ errors }) => {
        if (!errors) return Promise.resolve();
        return Promise.reject();
      });
  };

  const onChange = (event) => { updateCheckboxList(event.target); };

  const bindCheckboxControls = (checkboxes) => checkboxes.map((box) => ({
    ...box,
    isChecked: isSelectedCheckbox(box.optionValue),
    onChange,
  }));

  const setupCheckboxGroups = (checkboxGroups) => checkboxGroups.map((group) => {
    const type = group.__typename; // eslint-disable-line no-underscore-dangle
    const checkboxGroup = { ...group }; // speading avoids read-only error

    if (type === 'SectionedFieldOptionList') {
      checkboxGroup.optionSections = group.optionSections.reduce(
        (acc, section) => [...acc, bindCheckboxControls(section)],
        [],
      );
    } else {
      checkboxGroup.options = bindCheckboxControls(checkboxGroup.options);
    }

    return checkboxGroup;
  });

  return (
    <MarketingPreferencesForm
      formRef={formRef}
      checkboxGroups={setupCheckboxGroups(marketingPreferencesCheckboxes)}
      isEdited={isEdited()}
      isErrorState={isErrorState}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      onUnsuccessfulSubmit={handleUnsuccessfulSubmit}
      isMFELegalModalEnabled={isMFELegalModalEnabled}
      text={({
        cancel,
        formError,
        legalLinks,
        pleaseTryAgain,
        processing,
        save,
        success,
        legalDisclaimer,
      })}
    />
  );
}

MarketingPreferencesFormContainer.defaultProps = { email: '' };
MarketingPreferencesFormContainer.propTypes = { email: PropTypes.string };
