import { gql } from '@apollo/client';
import { textPairFragment } from '../../query/fragments';

export const LOYALTY_BLOCK_QUERY = gql`
  query LOYALTY_BLOCK_QUERY {
    textFor {
      loyaltyTierImageAltText: pair(pairKey: "loyaltyTierLogoLabel") {...textPair }
      welcomeMessageText: nonCachedPair(pairKey: "welcomeMessage") {...textPair }
      loyaltyMemberSinceMessageText: nonCachedPair(pairKey: "loyaltyMemberSinceMessage") {...textPair }
      loyaltyCrossBorderRepudiationText: pair(pairKey: "loyaltyCrossBorderRepudiationText") {...textPair }
      switchAccountInfo: pair(pairKey: "loyaltySwitchAccountRepudiationText") {...textPair }
      switchAccountButtonText: pair(pairKey: "loyaltySwitchAccountText") {...textPair }
    }
    config {
      loyaltyWelcomeMessageImageUrl: nonCachedString(name: "loyaltyWelcomeMessageImageUrl")
      isVip: bool(name: "isVip")
      brand: string(name: "customerCareIcons")
      isLoyaltyCrossBorderRepudiation: bool(name: "isLoyaltyCrossBorderRepudiation")
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
      isSwitchAccount: bool(name: "isSwitchAccount")
    }
    accountInfoEnabled: featureFlag(
      key: "enable-mfe-loyalty-account-info",
      defaultValue: false
    )
  }
  ${textPairFragment}
`;

export const MY_REWARDS_QUERY = gql`
  query myRewardsTextQuery {
    textFor {
      myRewardsText: pair(pairKey: "myRewardsText") {...textPair}
      redeemInBagText: pair(pairKey: "redeemInBag") {...textPair}
      exclusionsApplyLabelText: pair(pairKey: "exclusionsApplyLabel") {...textPair}
    }
    coupons {
        ... on Reward {
          id
          title {...textPair}
          description {...textPair}
          badge
          expiration {...textPair}
          exclusionLink
        }
        ... on Promotion {
          id
          title {...textPair}
          description {...textPair}
          badge
          expiration {...textPair}
          exclusionLink
        }
      }
    }
  ${textPairFragment}
`;

export const VIP_LOYALTY_QUERY = gql`
  query VIP_LOYALTY_QUERY {
    textFor {
      vipTitle: nonCachedPair(pairKey: "vipTitle") {...textPair}
      vipDescription: nonCachedPair(pairKey: "vipDescription") {...textPair}
      minValueLabelProgressBar: pair(pairKey: "minValueProgressBarLabel") {...textPair}
      maxValueLabelProgressBar: pair(pairKey: "maxValueProgressBarLabel") {...textPair}
      vipOffersText: pair(pairKey: "vipOffersText") {...textPair}
      vipFreeShippingText: pair(pairKey: "vipFreeShippingText") {...textPair}
      vipLoyaltyPointsText: pair(pairKey: "vipLoyaltyPointsText") {...textPair}
    }
    config {
      currentProgressBarValue: int(name: "vipCurrentProgressBarValue")
      brand: string(name: "customerCareIcons")
      }
  }
  ${textPairFragment}
`;

export const MY_POINTS_LOYALTY_QUERY = gql`
  query rewardsTextQuery {
    textFor {
      myPointsTitle: nonCachedPair(pairKey: "myPointsTitle") {...textPair}
      myPointsDescription: nonCachedPair(pairKey: "myPointsDescription") {...textPair}
      pointsHistoryTitle: pair(pairKey: "pointsHistoryTitle") {...textPair}
      minValueLabelProgressBar: pair(pairKey: "minValueLabelProgressBar") {...textPair}
      maxValueLabelProgressBar: pair(pairKey: "maxValueLabelProgressBar") {...textPair}
    }
    config {
      pointsHistoryUrl: string(name: "pointsHistoryUrl")
      myPointsMaxProgressBarValue: int(name: "myPointsMaxProgressBarValue")
      myPointsCurrentProgressBarValue: int(name: "myPointsCurrentProgressBarValue")
    }
  }
  ${textPairFragment}
`;

export const POINTS_HISTORY_QUERY = gql`
  query pointsHistory {
    textFor {
      inStorePurchaseText: pair(pairKey: "pointsHistoryInStorePurchaseText") {...textPair}
      findOrderDetailText: pair(pairKey: "pointsHistoryFindOrderDetailText") {...textPair}
      findOrderStoreText: pair(pairKey: "pointsHistoryFindOrderStoreText") {...textPair}
      barcodeText: pair(pairKey: "pointsHistoryBarcodeText") {...textPair}
      onlinePurchaseText: pair(pairKey: "pointsHistoryOnlinePurchaseText") {...textPair}
      emailText: pair(pairKey: "pointsHistoryEmailText") {...textPair}
      findOrderOnlineText: pair(pairKey: "pointsHistoryFindOrderOnlineText") {...textPair}
      title: pair(pairKey: "pointsHistoryTitle") {...textPair}
      missingPointsTitle: pair(pairKey: "missingPointsTitleText") {...textPair}
      instoreAltText: pair(pairKey: "pointsHistoryInstoreAltText") {...textPair}
      onlineAltText: pair(pairKey: "pointsHistoryOnlineAltText") {...textPair}
      missingPointsDescription: nonCachedPair(pairKey: "missingPointsDescriptionText") {...textPair}
    }
    config {
      onlineURL: string(name: "pointsHistoryOnlineURL")
      inStoreURL: string(name: "pointsHistoryInStoreURL")
    }
    disableMissingPointsForm: featureFlag(
      key: "disable-missing-points-form",
      defaultValue: false
    )
  }

  ${textPairFragment}
`;

export const POINTS_HISTORY_MISSING_POINTS_FORM = gql`
  query pointsHistoryMissingPointsForm {
    textFor {
      submit: pair(pairKey: "pointsHistorySubmitText") {...textPair}
      inputPlaceholder: pair(pairKey: "pointsHistoryInputPlaceholder") {...textPair}
      processing: pair(pairKey: "processing") {...textPair}
      addedToList: pair(pairKey: "pointsHistoryAddedToListText") {...textPair}
      missingPointsDefaultErrorDescription: pair(pairKey: "missingPointsDefaultErrorDescription") {...textPair}
      missingPointsDefaultErrorTitle: pair(pairKey: "missingPointsDefaultErrorTitle") {...textPair}
      errorSomethingWentWrong: pair(pairKey: "errorSomethingWentWrong") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const GET_POINTS_HISTORY = gql`
  query GetPointsHistory {
    textFor {
      noRecords: pair(pairKey: "pointsHistoryNoRecords") {...textPair}
      listDescription: pair(pairKey: "pointsHistoryListDescription") {...textPair}
      buttonLoadMore: pair(pairKey: "buttonLoadMore") {...textPair}
    }
    pointsHistory: getPointsHistory {
      pointsHistoryItems {
        id
        createdAt
        isGiftTier
        pointBalance
        status
        details {
          description { ...textPair }
          notes { ...textPair }
          transactionId { ...textPair }
        }
      }
      isLastPage
      intitialNumberOfRecordsToShow
    }
  }

  ${textPairFragment}
`;

export const POINTS_HISTORY_MISSING_POINTS_FORM_MUTATION = gql`
  mutation addOrderToPointsHistory($orderNumber: String!) {
    addOrderToPointsHistory(orderNumber: $orderNumber) {
      success
      status
      messageTitle {
        key
        value
      }
      messageDescription {
        key
        value
      }
    }
  }
`;

export const SWITCH_ACCOUNT_FORM_QUERY = gql`
  query SwitchAccountInfo {
    textFor {
      buttonPleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      buttonProcessing: pair(pairKey: "processing") { ...textPair }
      buttonSuccess: pair(pairKey: "success") { ...textPair }
      switchAccountText: pair(pairKey: "loyaltySwitchAccountText") { ...textPair }
      switchAccountMessage: nonCachedPair(pairKey: "loyaltySwitchAccountFormText") { ...textPair }
      switchAccountLegal: pair(pairKey: "loyaltySwitchAccountLegalText") { ...textPair }
      switchAccountAccept: pair(pairKey: "loyaltySwitchAccountAccept") { ...textPair }
      switchAccountDecline: pair(pairKey: "loyaltySwitchAccountDecline") { ...textPair }
      switchAccountError: pair(pairKey: "error") { ...textPair }
    }
    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
  }

  ${textPairFragment}
`;

export const SWITCH_ACCOUNT_MUTATION = gql`
  mutation SwitchAccount($switchAccepted: Boolean!) {
    switchAccount(switchAccepted: $switchAccepted) { success }
  }
`;
