export const payment = {
  cardBrand: '001',
  cardExpireMonth: '07',
  cardExpireYear: '25',
  cardNumber: 'XXXX XXXX XXXX 1111',
  displayInfo: null,
  requiresCVV: true,
  id: '131002',
};

export const cardNumberObscureChar = '\u2022';

export const ccRegexArray = [
  { brand: '001', icon: 'visa', regexValue: /^4\d{3}/ },
  { brand: '002', icon: 'mastercard', regexValue: /^5[1-5]\d{2}|^2\d{1}/ },
  { brand: '003', icon: 'amex', regexValue: /^3[47]\d{2}/ },
  { brand: '004', icon: 'discover', regexValue: /^6(011|2\d{2}|4[4-9]\d{1}|5\d{2})|^8\d{3}|^30[0-5]\d{1}|^3095|^3[689]\d{2}/ },
  { brand: '007', icon: 'jcb', regexValue: /^30[89]\d{1}|^3[01][09][^345]|^3[135]\d{2}/ },
];

export const SAVED_PAYMENT_LIMIT = 11;

export const mockContextValues = {
  text: {
    addPaymentText: { value: 'Add Payment' },
    buttonAddNew: { value: 'Add Payment' },
    editButton: { value: 'Edit' },
    buttonMakeDefault: { value: 'Set As Default' },
    cardAriaEndingIn: { value: 'validus tonsor avaritia' },
    cardDefaultText: { value: 'Default Payment' },
    cardTitle: { value: 'Payment Method' },
    formError: { value: 'There was a problem with the information entered.', key: 'FORM_ERROR' },
    noPayments: { value: 'No Saved Payments' },
    pageTitle: { value: 'Saved Payments' },
    paymentModalLegalText: {
      value: `<ul class='pipe-delimited-links'><li class='pipe-delimited-link'><a href='{0}'>Website Terms of Use</a></li>
      <li class='pipe-delimited-link'><a href='{1}'>Privacy Policy</a></li></ul>`,
    },
    pleaseTryAgain: { value: 'Please Try Again' },
    processing: { value: 'Processing' },
    removePaymentText: { value: 'Remove Payment' },
    paymentApiError: { value: 'There was an error submitting your request.' },
    success: { value: 'Success' },
    updatePaymentText: { value: 'Update Payment Info' },
  },
  config: {
    pxpExponent: 'AQAB',
    pxpModulus: `tnn0MtVgqVELtIiN3QQcL3qeZ7DOacr39lY
    AD1u7/BGUmD7mItdk+FM13ghNmggQD7rcPtabSFsjis
    yCqah3r1OrYP6dITjGcBACipCnR1A1FLYY8SoGGkPLb
    zQGnaj06tDHUbF8nZYy9UZ5Ef3dXMqUc2JXvW0e7SSG
    zt3f1/cT4/94IrPR78wIBfRWX46KJ6fSBT3GcC1aBKG
    b5IwMEMUJwLadOF7h+Lb5P4HoAH9HGdWlAAj7mDy/z4
    m8EDQjYi8XTG2zVJIhfv/TLnwnf6U/lBbUb0H7/1Mli
    W2UVceYWp1KX1ZvOXoYvf0h02r+EWdiT0x00LzQYcvwVbsCAQ==`,
  },
  ccRegexArray,
  isMFELegalModalEnabled: false,
};

export const mockContextValuesWithLegalModal = {
  text: {
    addPaymentText: { value: 'Add Payment' },
    buttonAddNew: { value: 'Add Payment' },
    editButton: { value: 'Edit' },
    buttonMakeDefault: { value: 'Set As Default' },
    cardAriaEndingIn: { value: 'validus tonsor avaritia' },
    cardDefaultText: { value: 'Default Payment' },
    cardTitle: { value: 'Payment Method' },
    formError: { value: 'There was a problem with the information entered.', key: 'FORM_ERROR' },
    noPayments: { value: 'No Saved Payments' },
    pageTitle: { value: 'Saved Payments' },
    paymentModalLegalText: {
      value: '<ul class="pipe-delimited-links"><li class="pipe-delimited-link"><button type="button" class="js-legal-policy-modal button-as-link" data-title="Terms of Use" data-textkey="LEGAL_SITE_USE">Website Terms of Use</button></li><li class="pipe-delimited-link"><button type="button" class="js-legal-policy-modal button-as-link" data-title="Privacy Policy" data-textkey="LEGAL_PRIVACY_POLICY">Privacy Policy</button></li></ul>',
    },
    pleaseTryAgain: { value: 'Please Try Again' },
    processing: { value: 'Processing' },
    removePaymentText: { value: 'Remove Payment' },
    paymentApiError: { value: 'There was an error submitting your request.' },
    success: { value: 'Success' },
    updatePaymentText: { value: 'Update Payment Info' },
  },
  config: {
    pxpExponent: 'AQAB',
    pxpModulus: `tnn0MtVgqVELtIiN3QQcL3qeZ7DOacr39lY
    AD1u7/BGUmD7mItdk+FM13ghNmggQD7rcPtabSFsjis
    yCqah3r1OrYP6dITjGcBACipCnR1A1FLYY8SoGGkPLb
    zQGnaj06tDHUbF8nZYy9UZ5Ef3dXMqUc2JXvW0e7SSG
    zt3f1/cT4/94IrPR78wIBfRWX46KJ6fSBT3GcC1aBKG
    b5IwMEMUJwLadOF7h+Lb5P4HoAH9HGdWlAAj7mDy/z4
    m8EDQjYi8XTG2zVJIhfv/TLnwnf6U/lBbUb0H7/1Mli
    W2UVceYWp1KX1ZvOXoYvf0h02r+EWdiT0x00LzQYcvwVbsCAQ==`,
  },
  ccRegexArray,
  isMFELegalModalEnabled: true,
};
