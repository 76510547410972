import { gql } from '@apollo/client';
import { textPairFragment, optionValuesFragment, addressFragment } from '../../query/fragments';
import {
  inputFragmentStr,
  multiSelectFragmentStr,
  formGroupFragmentStr,
  formFragmentStr,
} from '../../query/fragmentStrings';

export const ADDRESS_TEXT_QUERY = gql`
  query AddressTextQuery {
    textFor {
      pageTitle: pair(pairKey: "addressPageTitle") { ...textPair }
      pageEmptyDesc: pair(pairKey: "addressPageEmptyDesc") { ...textPair }
      cardTitle: pair(pairKey: "addressCardTitle") { ...textPair }
      cardDefaultText: pair(pairKey: "addressCardDefault") { ...textPair }
      modalAddHeading: pair(pairKey: "addAddress") { ...textPair }
      modalEditHeading: pair(pairKey: "editAddress") { ...textPair }
      addAddressCloseLabel: pair(pairKey: "addAddressCloseLabel") { ...textPair }
      editAddressCloseLabel: pair(pairKey: "editAddressCloseLabel") { ...textPair }
      addressFormLegal: pair(pairKey: "addressFormLegal") { ...textPair }
      addressFormPreloadErrorMessage: pair(pairKey: "addressFormPreloadErrorMessage") { ...textPair }
      buttonAddAddress: pair(pairKey: "addAddress") { ...textPair }
      buttonUpdate: pair(pairKey: "update") { ...textPair }
      buttonChange: pair(pairKey: "change") { ...textPair }
      buttonRemove: pair(pairKey: "remove") { ...textPair }
      buttonEdit: pair(pairKey: "editButton") { ...textPair }
      buttonMakeDefault: pair(pairKey: "makeDefault") { ...textPair }
      buttonProcessing: pair(pairKey: "processing") { ...textPair }
      buttonSuccess: pair(pairKey: "success") { ...textPair }
      buttonPleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      buttonAddNewAddress: pair(pairKey: "addressPageButtonAdd") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const ADDRESS_PAGE_QUERY = gql`
  query GetAddressPageData {
    savedAddresses: getAllAddresses {
      defaultId,
      addresses { ...address }
    }
    config {
      defaultCountryByRegion: string(name: "defaultShipToCountryByRegion")
    }
  }

  ${addressFragment}
`;

export const ADDRESS_FORM_QUERY = gql`
  query AddressFormQuery($country : String) {
    countryConfig(countryCode: $country) { ...location }
    textFor {
      addressForm(country: $country) { ...form }
    }

    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
  }

  fragment location on CountryConfig {
    id
    label { ...textPair }
  }

  ${textPairFragment}
  ${optionValuesFragment}
  ${inputFragmentStr}
  ${multiSelectFragmentStr}
  ${formGroupFragmentStr}
  ${formFragmentStr}
`;

export const GET_CITIES_QUERY = gql`
  query getCities($country: String, $state: String) {
    config {
      cities(country: $country, state: $state) {
        id
        label {
          key
          value
        }
      }
    }
  }
`;

export const GET_DISTRICTS_QUERY = gql`
  query getDistricts($country: String, $state: String, $city: String) {
    config {
      districts(country: $country, state: $state, city: $city) {
        id
        label {
          key
          value
        }
      }
    }
  }
`;

export const ADDRESS_MODAL_TEXT_QUERY = gql`
  query AddressModalText {
    textFor {
      addHeading: pair(pairKey: "addAddress") { ...textPair }
      editHeading: pair(pairKey: "editAddress") { ...textPair }
      addAddressCloseLabel: pair(pairKey: "addAddressCloseLabel") { ...textPair }
      editAddressCloseLabel: pair(pairKey: "editAddressCloseLabel") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const GET_ADDRESS_QUERY = gql`
  query GetAddress($id: ID!) {
    address: getAddress(id: $id) { ...address }
  }

  ${addressFragment}
`;

export const ADD_ADDRESS_MUTATION = gql`
  mutation AddAddress($address: AddressInput!) {
    addAddress(address: $address) { success }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress($id: ID!, $address: AddressInput!) {
    updateAddress(id: $id, address: $address) { success }
  }
`;

export const REMOVE_ADDRESS_MUTATION = gql`
  mutation RemoveAddress($id: ID!) {
    removeAddress(id: $id) { success }
  }
`;

export const SET_DEFAULT_ADDRESS_MUTATION = gql`
  mutation SetDefaultAddress($id: ID!) {
    setDefaultAddress(id: $id) { success }
  }
`;
