import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useForm, useController } from 'react-hook-form';
import { useWindowWidth } from '@react-hook/window-size';
import {
  Checkbox,
  CheckboxGroup,
  ErrorMessage,
  Icon,
  LegalText,
  RadioButton,
  RadioButtonGroup,
} from 'anf-core-react';
import { useDatabridgeSubscribe } from '@xp-utilities/web';
import FormWrapper from '../FormStructure/FormWrapper';
import FormGroup from '../FormStructure/FormGroup';
import FormCell from '../FormStructure/FormCell';
import { useCheckboxGroup, useTealiumTrigger, useLegalLinkEvents } from '../../hooks';
import EmailInputField from '../Common/EmailInputField/EmailInputField';
import useButtonState from '../Common/ButtonState/useButtonState';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import { SUBSCRIBE_MUTATION, SUBSCRIBE_FORM_QUERY } from './operations';
import { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';
import ControlledBirthdayFields from '../Common/ControlledBirthdayFields/ControlledBirthdayFields';
import { ModalContextProvider } from '../../context/ModalContext';
import LegalModal from '../Common/LegalModalBlock/LegalModal';
import LegalButton from '../Common/LegalModalBlock/LegalButton';

let textCache = null;

const NAMES = {
  email: 'email',
  shoppingPreferences: 'shopping-preference',
  birthMonth: 'birth-month',
  birthDay: 'birth-day',
  birthYear: 'birth-year',
  brands: 'marketing-brands[]',
  legal: 'legal-check',
};

const propTypes = { email: PropTypes.string, onSuccessfulSubmit: PropTypes.func };
const defaultProps = { email: '', onSuccessfulSubmit: () => {} };

export default function SubscribeForm({ email, onSuccessfulSubmit }) {
  const [renderButton, registerPromise] = useButtonState();
  const [marketingBrands, , setMarketingBrands, isMarketingBrandChecked] = useCheckboxGroup([]);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      [NAMES.email]: email, [NAMES.brands]: marketingBrands, [NAMES.legal]: false,
    },
  });

  // Window Width
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  // capture legal text key from TMNT
  const legalLinkRef = useRef(null);
  const [legalTmntKey, setLegalTmntKey] = useState(null);
  useLegalLinkEvents(legalLinkRef, (value) => {
    setLegalTmntKey(value);
  });

  const { field: shoppingPreferencesField } = useController({
    name: NAMES.shoppingPreferences, control,
  });

  const { field: marketingBrandsField } = useController({ name: NAMES.brands, control });

  const { field: legalField, fieldState: legalFieldState } = useController({
    name: NAMES.legal,
    control,
    rules: {
      validate: (value) => {
        if (!textCache?.legal?.check) return true; // not required
        return value;
      },
    },
  });
  const userMarketingFlag = marketingBrands.length ? 'true' : 'false';
  const analyticsData = {
    eventName: 'analytics.account_create_success',
    eventData: {
      event_type: 'account_create_success',
      event_Id: 'marketing_brand',
      user_marketing_flag: userMarketingFlag,
    },
  };
  // Tealium Trigger
  const triggerTealiumEmailSubscribeEvent = useTealiumTrigger('subscription', 'EMAIL_SUBSCRIBE');
  const trackSuccessfulMarketingBrands = useCustomTealiumTrigger(analyticsData);

  const pageKind = useDatabridgeSubscribe('page.pageKind');
  const originatingSection = pageKind === 'subscribe' ? 'Search' : 'Footer';

  // Submit Mutation
  const [subscribe] = useMutation(SUBSCRIBE_MUTATION, {
    onCompleted: ({ subRequest }) => {
      if (subRequest.success) {
        onSuccessfulSubmit();
        triggerTealiumEmailSubscribeEvent();
        trackSuccessfulMarketingBrands();
      } else {
        subRequest.errors.forEach((error) => {
          switch (error?.field) {
            case 'email':
              setError(NAMES.email);
              break;
            case 'formAge':
              setError('age', { message: error.text?.value });
              break;
            case 'legal':
              setError(NAMES.legal);
              break;
            default:
              setError('form', { message: error.text?.value });
              break;
          }
        });
      }
    },
    onError: () => { setError('form', { message: textCache?.errorSomethingWentWrong.value }); },
  });

  // Query SubcribeForm Text
  const { loading, error, data } = useQuery(SUBSCRIBE_FORM_QUERY);

  // Due to Server Side render, need to re-render on page load
  useEffect(() => { setWindowWidth(retrievedWindowWidth); }, [retrievedWindowWidth]);

  // Callbacks
  const onSubmit = (formData = {}) => {
    try {
      const call = new Promise((resolve, reject) => {
        subscribe({
          variables: {
            email: formData.email,
            additionalBrandList: marketingBrands,
            shoppingPreference: formData[NAMES.shoppingPreferences],
            birthMonth: formData[NAMES.birthMonth],
            birthDay: formData[NAMES.birthDay],
            birthYear: formData[NAMES.birthYear],
            legal: !textCache?.legal?.check ? true : formData[NAMES.legal],
            originatingSection,
          },
        })
          .then(({ data: responseData }) => {
            const { subRequest } = responseData;
            const { success } = subRequest;
            if (!success) reject();
            else resolve();
          })
          .catch(() => { reject(); });
      });

      registerPromise(call);
    } catch { /* do nothing */ }
  };

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const {
    buttonError,
    buttonProcessing,
    buttonSubmit,
    buttonSuccess,
    email: emailText,
    footerSubscribeFewMoreStepsToGo,
    footerSubscribeOfficiallyOnEmailList,
    legal,
    optional,
    shoppingPreferenceText,
    subscribeCheckboxGroups,
    subscriptionDescription,
    legalDisclaimer,
  } = data.textFor;

  const { showSubscriptionDescription } = data.config;

  textCache = data.textFor;

  // Rendering functions
  const renderShoppingPreferences = () => shoppingPreferenceText.options.map((option) => (
    <RadioButton
      description={option.text.value}
      id={`subscribe-form-shopping-preference-${option.optionValue}`}
      isChecked={option.optionValue === shoppingPreferencesField.value}
      key={option.text.value}
      label={option.text.value}
      name={shoppingPreferencesField.name}
      onChange={shoppingPreferencesField.onChange}
      value={option.optionValue}
    />
  ));

  const renderCheckboxDescription = ({ desc, title }) => (
    <>
      <span className="h4">
        <TmntText tmnt={title} />
      </span>
      { desc && <TmntText tmnt={desc} /> }
    </>
  );

  const renderBrandOptions = (brands = []) => brands.map((brandOption) => (
    <Checkbox
      description={renderCheckboxDescription({
        desc: brandOption.description,
        title: brandOption.text,
      })}
      id={`subscribe-form-marketing-brands-${brandOption.optionValue}`}
      isChecked={isMarketingBrandChecked(brandOption.optionValue)}
      key={brandOption.text.value}
      name={marketingBrandsField.name}
      onChange={(e) => setMarketingBrands(e.target)}
      value={brandOption.optionValue}
    />
  ));

  const renderLegalText = () => (
    data?.isMFELegalModalEnabled ? (
      <div data-testid="legal-text">
        <LegalButton
          text={textCache?.legal?.text}
          ref={legalLinkRef}
        />
        {legalTmntKey && (
        <ModalContextProvider>
          <LegalModal
            legalText={legalTmntKey}
            onClose={() => setLegalTmntKey(null)}
          />
        </ModalContextProvider>
        )}
      </div>
    ) : (
      <LegalText>
        <TmntHtml tmnt={textCache?.legal?.text} />
      </LegalText>
    )
  );

  const renderErrorMessage = (id, isInvalid, tmnt) => {
    if (!isInvalid) return null;
    if (!tmnt) return null;

    return (
      <ErrorMessage id={id}>
        <TmntText tmnt={tmnt} />
      </ErrorMessage>
    );
  };

  const renderFormError = (errors) => {
    const formError = errors.form;

    if (Object.keys(formState.errors).length <= 0) return null;

    return (
      <FormCell>
        <ErrorMessage id="subscribe-form-error-message">
          { formError?.message ?? (<TmntText tmnt={textCache?.formError} />) }
        </ErrorMessage>
      </FormCell>
    );
  };

  const renderFormAgeError = (errors) => {
    const ageError = errors?.age;

    if (!ageError) return null;

    return (
      <FormCell>
        <ErrorMessage id="subscribe-form-age-error-message">
          { ageError.message ?? (<TmntText tmnt={textCache?.formAgeError} />) }
        </ErrorMessage>
      </FormCell>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} name="email-subscribe-form" noValidate>
      <FormWrapper>
        <FormGroup>
          {
            !showSubscriptionDescription ? null : (
              <FormCell>
                <TmntHtml tmnt={subscriptionDescription} />
              </FormCell>
            )
          }
          <FormCell>
            {
              email ? (
                <div data-testid="footer-subscribe-description">
                  <p><TmntText tmnt={footerSubscribeOfficiallyOnEmailList} /></p>
                  <p><TmntText tmnt={footerSubscribeFewMoreStepsToGo} /></p>
                </div>
              ) : (
                <EmailInputField
                  id="subscribe-form-email"
                  name={NAMES.email}
                  isRequired
                  label={emailText?.label?.value}
                  control={control}
                >
                  {
                    renderErrorMessage(
                      'subscribe-form-email-error-message',
                      Object.keys(formState.errors).includes(NAMES.email),
                      emailText.error,
                    )
                  }
                </EmailInputField>
              )
            }
          </FormCell>
          <FormCell>
            <RadioButtonGroup
              variant={windowWidth > 600 ? 'horizontal' : undefined}
              legend={`${shoppingPreferenceText.label.value} (${optional.value})`}
            >
              { renderShoppingPreferences() }
            </RadioButtonGroup>
          </FormCell>
          <ControlledBirthdayFields
            id="subscribe-form"
            name="birthdayFields"
            control={control}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
          />
          {
            subscribeCheckboxGroups.map(({ label, options }, index) => {
              const i = index;

              return (
                <React.Fragment key={`subscribeCheckboxGroupFragment_${i}`}>
                  <FormCell>
                    <CheckboxGroup
                      // first group is optional
                      optionalText={i === 0 ? `(${optional.value})` : null}
                      title={!label ? <div /> : <TmntText tmnt={label} />}
                    >
                      { renderBrandOptions(options) }
                    </CheckboxGroup>
                  </FormCell>
                  <FormCell>
                    <hr />
                  </FormCell>
                </React.Fragment>
              );
            })
          }
          <FormCell>
            <TmntText classNames="subscribe-legal-disclaimer" tmnt={legalDisclaimer} />
            {
              legal.check && (
                <Checkbox
                  description={renderLegalText()}
                  id="subscribe-form-legal-check"
                  isChecked={legalField.value}
                  isInvalid={legalFieldState.invalid}
                  isRequired
                  name={legalField.name}
                  onChange={legalField.onChange}
                  value="y"
                >
                  {
                    renderErrorMessage(
                      'subscribe-form-legal-check-error-message',
                      legalFieldState.invalid,
                      legal.error,
                    )
                  }
                </Checkbox>
              )
            }
            { !legal.check && renderLegalText() }
          </FormCell>
          { renderFormError(formState.errors) }
          { renderFormAgeError(formState.errors) }
          <FormCell>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={buttonSubmit} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={buttonProcessing} />), isFullWidth: true, variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={buttonError} />), isFullWidth: true, variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={buttonSuccess} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

SubscribeForm.defaultProps = defaultProps;
SubscribeForm.propTypes = propTypes;
