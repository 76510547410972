import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import LoyaltyWelcomeMessage from './LoyaltyWelcomeMessage';
import { LOYALTY_BLOCK_QUERY } from './operations';
import VIPLoyaltyCard from './VIPLoyaltyCard';
import MyPointsCard from './MyPointsCard';
import MyRewards from './MyRewards';
import ESpot from '../ESpot/ESpot';
import Row from '../Common/Grid/Row';
import Column from '../Common/Grid/Column';
import Loading from '../Common/Loading/Loading';
import AccountInfo from './AccountInfo';
import { TmntText } from '../Common/Text';

export default function LoyaltyBlock() {
  const { data, loading: queryLoading, error } = useQuery(LOYALTY_BLOCK_QUERY, {
    context: { batch: true },
    ssr: false,
  });

  useEffect(() => {
    const brand = window?.digitalData?.get('page.brand') || 'anf';
    const brandRewardsPage = brand === 'hol' ? 'HollisterHouseRewards' : 'MyAbercrombie';
    const pageDescription = brand === 'hol' ? 'hollister house rewards' : 'my abercrombie';
    const params = new URLSearchParams(window.location.search);
    params.set('pagefm', brandRewardsPage);
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
    window.digitalData?.set?.('page.pageDescription', pageDescription);
  }, []);

  if (error) return ERROR_MESSAGE;

  const textFor = data?.textFor || {};
  const config = data?.config || {};
  const loyaltyCrossBorderRepudiation = (config?.isLoyaltyEnabled
    && config?.isLoyaltyCrossBorderRepudiation);
  const { accountInfoEnabled = false } = data || {};

  const welcomeMessage = () => (
    <LoyaltyWelcomeMessage
      loyaltyTierImageSrc={config?.loyaltyWelcomeMessageImageUrl}
      loyaltyTierImageAltText={textFor?.loyaltyTierImageAltText.value}
      memberSince={textFor?.loyaltyMemberSinceMessageText}
      welcomeMessageText={textFor?.welcomeMessageText}
      isVip={config?.isVip}
      brand={config?.brand}
    />
  );

  const loyaltyCards = () => (
    <div className="loyalty-card-container">
      <Row>
        <Column xmd="8" md="8" xxl="4">
          <MyPointsCard />
        </Column>
        <Column xmd="8" md="8" xxl="4">
          <VIPLoyaltyCard />
        </Column>
      </Row>
    </div>
  );

  return (
    <div className="loyalty-block-container">
      {!loyaltyCrossBorderRepudiation && (
        <Loading isLoading={queryLoading}>
          {!queryLoading && (
            <>
              { welcomeMessage() }
              { loyaltyCards() }
              <ESpot espotId="cust-accountbanner2" />
              <div className="rewards-container">
                <MyRewards />
              </div>
              <ESpot espotId="cust-accountbanner3" />
            </>
          )}
        </Loading>
      )}
      {(loyaltyCrossBorderRepudiation && accountInfoEnabled) && (
        <AccountInfo
          crossBorderText={textFor?.loyaltyCrossBorderRepudiationText}
          isSwitchAccount={config?.isSwitchAccount}
          switchAccountInfoText={textFor?.switchAccountInfo}
          switchAccountButtonText={textFor?.switchAccountButtonText}
        />
      )}
      {(loyaltyCrossBorderRepudiation && !accountInfoEnabled) && (
        <h3 className="h3">
          <TmntText tmnt={textFor?.loyaltyCrossBorderRepudiationText} />
        </h3>
      )}
    </div>
  );
}
