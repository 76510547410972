import PropTypes from 'prop-types';
import { inputProp, multiSelectProp, textProp } from '../../tools/customProps';

const FormHandlersPropTypes = {
  onSuccessfulSubmission: PropTypes.func,
  onFailedSubmission: PropTypes.func,
};

const FormHandlersDefaultProps = {
  onSuccessfulSubmission: undefined,
  onFailedSubmission: undefined,
};

export const formErrorMessageCellPropTypes = {
  formState: PropTypes.shape({ errors: PropTypes.shape({ message: textProp }) }),
};

export const loyaltyConversionTogglePropTypes = {
  children: PropTypes.node.isRequired,
  control: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isLoyaltyConversionShown: PropTypes.bool,
  submitCall: PropTypes.object,
};

export const loyaltyConversionToggleDefaultProps = {
  isLoading: false,
  isLoyaltyConversionShown: false,
  submitCall: undefined,
};

export const loyaltyAboutLinkFormCellPropTypes = {
  isLoading: PropTypes.bool,
  isLoyaltyEnabled: PropTypes.bool,
  label: textProp,
  link: PropTypes.string,
};

export const loyaltyAboutLinkFormCellDefaultProps = {
  isLoading: false,
  isLoyaltyEnabled: false,
  label: undefined,
  link: undefined,
};

export const StepOnePropTypes = {
  control: PropTypes.object.isRequired,
  email: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.oneOfType([multiSelectProp, inputProp])),
  isRemembered: PropTypes.bool,
  onEmailFocus: PropTypes.func,
  onPasswordFocus: PropTypes.func,
  password: PropTypes.string,
};

export const StepOneDefaultProps = {
  email: undefined,
  fields: [],
  isRemembered: false,
  onEmailFocus: undefined,
  onPasswordFocus: undefined,
  password: undefined,
};

export const SignInFormPropTypes = {
  email: PropTypes.string,
  keepMeSignedIn: PropTypes.bool,
  onJoinClick: PropTypes.func,
  password: PropTypes.string,
  ...FormHandlersPropTypes,
};

export const SignInFormDefaultProps = {
  email: undefined,
  keepMeSignedIn: undefined,
  onJoinClick: undefined,
  password: undefined,
  ...FormHandlersDefaultProps,
};

export const RememberedSignInFormPropTypes = {
  email: PropTypes.string.isRequired,
  onNotYouClick: PropTypes.func,
  ...FormHandlersPropTypes,
};

export const RememberedSignInFormDefaultProps = {
  onNotYouClick: undefined,
  ...FormHandlersDefaultProps,
};

export const StepTwoPropTypes = {
  control: PropTypes.object.isRequired,
  onMarketingBrandsChange: PropTypes.func,
  queryData: PropTypes.object,
};

export const StepTwoDefaultProps = {
  queryData: {},
  onMarketingBrandsChange: undefined,
};

export const JoinFormPropTypes = {
  onIsExistingUser: PropTypes.func,
  onSignInClick: PropTypes.func,
  ...FormHandlersPropTypes,
};

export const JoinFormDefaultProps = {
  onIsExistingUser: undefined,
  onSignInClick: undefined,
  ...FormHandlersDefaultProps,
};

export const RememberedFormContainerPropTypes = {
  ...FormHandlersPropTypes,
  onLogoutCompleted: PropTypes.func,
};

export const RememberedFormContainerDefaultProps = {
  ...FormHandlersDefaultProps,
  onLogoutCompleted: undefined,
};

export const AuthenticationFormPropTypes = { ...FormHandlersPropTypes };
export const AuthenticationFormDefaultProps = { ...FormHandlersDefaultProps };

export const AuthenticationFormWrapperPropTypes = { children: PropTypes.node.isRequired };

export const MobileSignInButtonPropTypes = {
  label: textProp.isRequired,
  onClick: PropTypes.func,
};

export const MobileSignInButtonDefaultProps = { onClick: () => {} };

export const SignInRestructurePropTypes = {
  children: PropTypes.node.isRequired,
  switchKey: PropTypes.string,
  hide: PropTypes.bool,
};

export const SignInRestructureDefaultProps = {
  switchKey: undefined,
  hide: false,
};
