import { gql } from '@apollo/client';
import {
  formResponseFragment,
  fieldOptionListValueFragment,
  textPairFragment,
} from '../../query/fragments';

export const SUBSCRIBE_MUTATION = gql`
  mutation SUBSCRIBE_MUTATION(
    $email: String!
    $additionalBrandList: [String]
    $shoppingPreference: String
    $birthMonth: String
    $birthDay: String
    $birthYear: String
    $legal: Boolean
    $originatingSection: String!
  ) {
    subRequest: subscribeRequest(
      email: $email
      additionalBrandList: $additionalBrandList
      shoppingPreference: $shoppingPreference
      birthMonth: $birthMonth
      birthDay: $birthDay
      birthYear: $birthYear
      legal: $legal
      originatingSection: $originatingSection
    ) {...formResponse}
  }

  ${formResponseFragment}
`;

export const SUBSCRIBE_FORM_QUERY = gql`
  query SUBSCRIBE_FORM_QUERY {
    textFor {
      buttonError: pair(pairKey: "error") {...textPair}
      buttonProcessing: pair(pairKey: "processing") {...textPair}
      buttonSubmit: pair(pairKey: "submit") {...textPair}
      buttonSuccess: pair(pairKey: "success") {...textPair}
      errorSomethingWentWrong: pair(pairKey: "errorSomethingWentWrong") {...textPair}
      footerSubscribeFewMoreStepsToGo: pair(pairKey: "footerSubscribeFewMoreStepsToGo") {...textPair}
      footerSubscribeOfficiallyOnEmailList: pair(pairKey: "footerSubscribeOfficiallyOnEmailList") {...textPair}
      formAgeError: pair(pairKey: "formAgeError") {...textPair}
      formError: pair(pairKey: "formError") {...textPair}
      optional: pair(pairKey: "optional") {...textPair}
      subscriptionDescription: pair(pairKey: "subscriptionDescription") {...textPair}
      legalDisclaimer: pair(pairKey: "subscribeLegalDisclaimer") {...textPair}
      email: textField(fieldKey: "email") {
        label {...textPair}
        error {...textPair}
      }
      subscribeCheckboxGroups: listFieldGroup(fieldKey: "subscribeCheckboxes") { ...fieldOptionListValues }
      shoppingPreferenceText: listField(fieldKey: "shoppingPreference") {...fieldOptionListValues}
      legal: legal(fieldKey: "subscribe") {
        text {...textPair}
        error {...textPair}
        check
      }
    }
    config {
      showSubscriptionDescription: bool(name: "showSubscriptionDescription")
    }
    isMFELegalModalEnabled: featureFlag(
      key: "has-mfe-legal-modals-enabled",
      defaultValue: false
    )
  }

  ${textPairFragment}
  ${fieldOptionListValueFragment}
`;
