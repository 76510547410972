import React, { useState, useRef, useContext } from 'react';
import {
  Button,
  LegalText,
  FieldGroup,
  ErrorMessage,
  ButtonGroup,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import { encrypt } from '../../tools/encryption/encryption';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import { PaymentsContext } from './PaymentsContextProvider';
import Icon from '../Common/Icon/Icon';
import CreditCardInput from '../Common/CreditCardInput/CreditCardInput';
import ExpirationDateInput from '../Common/ExpirationDateInput/ExpirationDateInput';
import ButtonState from '../Common/ButtonState/ButtonState';
import {
  findBadParams, getCardBrand, isCardNumberInvalid, isExpDateInvalid,
} from '../../tools/CreditCardHelper/CreditCardHelper';
import { SAVED_PAYMENT_LIMIT } from './paymentConstants';
import { useLegalLinkEvents } from '../../hooks';
import LegalButton from '../Common/LegalModalBlock/LegalButton';
import { ModalContextProvider } from '../../context/ModalContext';
import LegalModal from '../Common/LegalModalBlock/LegalModal';

const defaultProps = {
  defaultCardBrand: undefined,
  defaultCardNumber: undefined,
  defaultExpDate: undefined,
  onSuccessfulSubmission: undefined,
};

const propTypes = {
  defaultCardBrand: PropTypes.string,
  defaultCardNumber: PropTypes.string,
  defaultExpDate: PropTypes.string,
  isEditState: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccessfulSubmission: PropTypes.func,
  isMFELegalModalEnabled: PropTypes.bool.isRequired,
};

export default function PaymentForm({
  defaultCardBrand,
  defaultCardNumber,
  defaultExpDate,
  isEditState,
  onRemove,
  onSubmit,
  onSuccessfulSubmission,
  isMFELegalModalEnabled,
}) {
  const [errorText, setErrorText] = useState(undefined);
  const [isCardNumberInvalidState, setIsCardNumberInvalidState] = useState(false);
  const [isExpDateInvalidState, setIsExpDateInvalidState] = useState(false);
  const formRef = useRef(null);
  const paymentsContext = useContext(PaymentsContext);
  const text = paymentsContext?.text;

  const legalLinkRef = useRef(null);
  const [legalTmntKey, setLegalTmntKey] = useState(null);
  useLegalLinkEvents(legalLinkRef, (value) => {
    setLegalTmntKey(value);
  });

  function encryptCardNumber(cardNumber, configCacheValue) {
    const encryptedCardNumber = encrypt({
      value: cardNumber,
      maxRetries: 0,
      convertToHex: true,
      exponent: configCacheValue?.pxpExponent,
      modulus: configCacheValue?.pxpModulus,
    });

    if (encryptedCardNumber === null) {
      setErrorText(text.formError);
    }

    return encryptedCardNumber;
  }

  const processSubmit = async (tempCardNumber, tempCardBrand, cardExpireMonth, cardExpireYear) => {
    setErrorText(undefined);

    // Do encryption here so plain text cc info
    // doesnt get passed over the network request to the BFE
    const encryptedCardNumber = !isEditState
      ? encryptCardNumber(tempCardNumber, paymentsContext?.config)
      : undefined;

    const card = {
      number: tempCardNumber,
      brand: tempCardBrand,
      expireMonth: cardExpireMonth,
      expireYear: cardExpireYear,
      encryptedNumber: encryptedCardNumber,
    };

    return onSubmit(card)
      .catch((err) => {
        const { graphQLErrors } = err;
        const badParams = findBadParams(graphQLErrors);

        if (badParams.isCardNumberInvalid) setIsCardNumberInvalidState(true);
        if (badParams.isCardExpDateInvalid) setIsExpDateInvalidState(true);

        if (!badParams.isCardNumberInvalid && !badParams.isCardExpDateInvalid) {
          setErrorText(text.formError);
        }

        throw err;
      });
  };

  const validateInputs = async () => {
    // if saved payments are already at limit of 11, give contextual error message
    if (paymentsContext.paymentCount >= SAVED_PAYMENT_LIMIT) {
      setErrorText(text.paymentLimitError);
      return Promise.reject();
    }

    const formData = new FormData(formRef.current);
    const tempExpDate = formData.get('expirationDateInput');
    const expDateArr = formData.get('expirationDateInput').split('/');
    const cardExpireMonth = expDateArr[0];
    const cardExpireYear = `20${expDateArr[1]}`;

    let tempCardNumber;
    if (isEditState) {
      tempCardNumber = defaultCardNumber;
    } else {
      tempCardNumber = formData.get('cardNumberInput').replace(/\s+/g, '');
    }

    const tempCardBrand = getCardBrand(tempCardNumber, paymentsContext.ccRegexArray);
    const isCardNumberInputInvalid = isCardNumberInvalid(tempCardNumber, tempCardBrand);
    const isCardExpDateInputInvalid = isExpDateInvalid(tempExpDate.replace(/\//g, ''));

    setIsCardNumberInvalidState(isCardNumberInputInvalid);
    setIsExpDateInvalidState(isCardExpDateInputInvalid);

    if (!isCardNumberInputInvalid && !isCardExpDateInputInvalid) {
      return processSubmit(tempCardNumber, tempCardBrand, cardExpireMonth, cardExpireYear);
    }
    return Promise.reject();
  };

  const handleOnRemove = async () => {
    setErrorText(undefined);

    return onRemove().catch((err) => {
      setErrorText(text.formError);
      throw err;
    });
  };

  function renderButtons() {
    const primaryButton = (
      <ButtonState
        asyncOnClick={validateInputs}
        onSuccess={onSuccessfulSubmission}
        initial={(
          <Button type="submit" variant="primary">
            <TmntText tmnt={!isEditState ? text.addPaymentText : text.updatePaymentText} />
          </Button>
        )}
        processing={(
          <Button variant="primary" isProcessing isDisabled>
            <TmntText tmnt={text.processing} />
          </Button>
        )}
        error={(
          <Button variant="primary" isDisabled>
            <TmntText tmnt={text.pleaseTryAgain} />
          </Button>
        )}
        success={(
          <Button variant="primary" isDisabled>
            <Icon icon="check" />
            <TmntText tmnt={text.success} />
          </Button>
        )}
      />
    );

    if (isEditState && defaultCardNumber) {
      return (
        <ButtonGroup variant="vertical-spaced">
          {primaryButton}
          <ButtonState
            asyncOnClick={handleOnRemove}
            onSuccess={onSuccessfulSubmission}
            initial={(
              <Button type="submit" variant="tertiary-dark">
                <TmntText tmnt={text.removePaymentText} />
              </Button>
            )}
            processing={(
              <Button variant="tertiary-dark" isProcessing isDisabled>
                <TmntText tmnt={text.processing} />
              </Button>
            )}
            error={(
              <Button variant="tertiary-dark" isDisabled>
                <TmntText tmnt={text.pleaseTryAgain} />
              </Button>
            )}
            success={(
              <Button variant="tertiary-dark" isDisabled>
                <Icon icon="check" />
                <TmntText tmnt={text.success} />
              </Button>
            )}
          />
        </ButtonGroup>
      );
    }

    return primaryButton;
  }

  return (
    <form
      ref={formRef}
      onSubmit={(e) => { e.preventDefault(); }}
      name="paymentForm"
      noValidate
    >
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <FieldGroup describedBy="Add Payment" legend="Payment Modal Inputs">
              <CreditCardInput
                cardNumError={text.formError}
                defaultCardBrand={defaultCardBrand}
                defaultCardNumber={defaultCardNumber}
                isDisabled={!!defaultCardNumber}
                isInvalid={isCardNumberInvalidState}
                label="Card Number"
                name="cardNumberInput"
                type="text"
              />
              <ExpirationDateInput
                defaultExpDate={defaultExpDate}
                expDateError={text.formError}
                isDisabled={false}
                isInvalid={isExpDateInvalidState}
                label="Expiration Date"
                maxLength={5}
                name="expirationDateInput"
                type="text"
              />
            </FieldGroup>
          </FormCell>
        </FormGroup>
        <FormGroup>
          {(text.paymentModalLegalText) && (
            <FormCell>
              {isMFELegalModalEnabled ? (
                <div data-testid="legal-text">
                  <LegalButton
                    text={text.paymentModalLegalText}
                    ref={legalLinkRef}
                  />
                  {legalTmntKey && (
                  <ModalContextProvider>
                    <LegalModal
                      legalText={legalTmntKey}
                      onClose={() => setLegalTmntKey(null)}
                    />
                  </ModalContextProvider>
                  )}
                </div>
              )
                : (
                  <LegalText>
                    <TmntHtml tmnt={text.paymentModalLegalText} />
                  </LegalText>
                )}
            </FormCell>
          )}
          {errorText && (
            <FormCell>
              <ErrorMessage id="payment-modal-form-error-message">
                <TmntText tmnt={errorText} />
              </ErrorMessage>
            </FormCell>
          )}
          <FormCell>
            {/* Made this method to minimize logic/help with readability in the template */}
            {renderButtons()}
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

PaymentForm.defaultProps = defaultProps;
PaymentForm.propTypes = propTypes;
