import { useQuery } from '@apollo/client';
import { SIGN_IN_RESTRUCTURE_QUERY } from './operations';
import { SignInRestructurePropTypes, SignInRestructureDefaultProps } from './props';

export default function SignInRestructure({
  children,
  hide,
  switchKey,
}) {
  const { loading, error, data = {} } = useQuery(SIGN_IN_RESTRUCTURE_QUERY, {
    context: { batch: true },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ssr: false,
  });

  const { featureConfig = { signInRestructureSwitch: '' } } = data;

  let isOn = false;

  try {
    const { signInRestructureSwitch } = featureConfig;
    isOn = JSON.parse(signInRestructureSwitch)[switchKey];
  } catch { return null; }

  if (loading) return null;
  if (error) return null;

  // When hide is ON, we negate the result
  const shouldRender = hide ? !isOn : isOn;

  return shouldRender ? children : null;
}

SignInRestructure.propTypes = SignInRestructurePropTypes;
SignInRestructure.defaultProps = SignInRestructureDefaultProps;
