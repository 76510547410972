import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import Loading from '../Common/Loading/Loading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import LoyaltyPointsCard from './LoyaltyPointsCard';
import ReviewItemsList from './ReviewItemsList';
import { WRITE_A_REVIEW_PAGE_CONFIG } from './operations';

export default function WriteAReviewPage() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('pagefm', 'write a review');
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
    window.digitalData?.set?.('page.pageDescription', 'write a review');
  }, []);

  const { loading, error: queryError, data: queryData = {} } = useQuery(WRITE_A_REVIEW_PAGE_CONFIG);

  if (queryError) return ERROR_MESSAGE;

  const { userState = {}, config = {} } = queryData;

  const { isLoggedIn = false } = userState;
  const { isLoyaltyEnabled, isWriteAReviewEnabled } = config;

  if (!isLoggedIn) return null;
  if (!isWriteAReviewEnabled || !isLoyaltyEnabled) return null;

  return (
    <Loading isLoading={loading}>
      <div className="write-a-review-block" data-testid="write-a-review-block">
        <LoyaltyPointsCard />
        <ReviewItemsList />
      </div>
    </Loading>
  );
}
