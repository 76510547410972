import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthenticationModal from './AuthenticationModal';
import LoggedInButton from './LoggedInButton';
import SignInButton from './SignInButton';
import SignInToaster from './SignInToaster';
import SignInMenu from './SignInMenu';
import { LoadingButton } from '../Messages/Messages';
import { SignInToasterContext } from '../../context/ModalContext';
import { DESKTOP_SIGN_IN_QUERY } from './operations';
import SignInRestructure from './SignInRestructure';

const DESKTOP_SIGN_IN = 'desktop-sign-in';

const renderContainer = (children) => (
  <div className="signin-block" data-testid={DESKTOP_SIGN_IN}>
    { children }
  </div>
);

function DesktopSignIn() {
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { loading, data = {} } = useQuery(
    DESKTOP_SIGN_IN_QUERY,
    {
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
      ssr: false,
    },
  );

  if (loading) return renderContainer(<LoadingButton />);

  const { userState = {}, config = {} } = data;
  const { isLoggedIn = false } = userState;
  const { isLoyaltyEnabled } = config;

  return renderContainer((
    <>
      {
        !isLoggedIn
          ? <SignInButton onClick={openToaster} />
          : <LoggedInButton onClick={openToaster} />
      }
      <AuthenticationModal isLoyaltyEnabled={isLoyaltyEnabled} />
      <SignInToaster>
        <SignInMenu isLoggedIn={isLoggedIn} />
      </SignInToaster>

      { /* OFF - Sign In Rewards Communication Modal */ }
      {/* { isLoggedIn && <SignInRewardsCommunicationModal /> } */}
    </>
  ));
}

/*
  Note: temporary setup to encapsulate the sign in block around
  the sign in restructure flag
*/
export default function DesktopSignInClient() {
  return (
    <SignInRestructure switchKey="desktop">
      <DesktopSignIn />
    </SignInRestructure>
  );
}
