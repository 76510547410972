import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsCookie from 'js-cookie';
import { useQuery, useMutation } from '@apollo/client';
import { Modal } from 'anf-core-react';
import TmntText from '../../Common/Text/TmntText';
import {
  GET_ADDRESS_QUERY,
  ADD_ADDRESS_MUTATION,
  REMOVE_ADDRESS_MUTATION,
  UPDATE_ADDRESS_MUTATION,
} from '../addressOperations';
import { AddressContext } from '../AddressContextProvider';
import AddressForm from './AddressForm';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';

export const EVENTS = { updated: 'address-form:updated' };

const propTypes = {
  countryId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addressId: PropTypes.string,
};

const defaultProps = { addressId: undefined };

export default function AddressModal({
  addressId,
  countryId,
  isOpen,
  onClose,
}) {
  const [shipToCountry, setShipToCountry] = useState(countryId);
  const isEdit = addressId !== undefined;
  const { textFor } = useContext(AddressContext);

  useEffect(() => {
    const uPref = jsCookie.get('uPref');
    const uPrefObj = uPref ? JSON.parse(uPref) : {};
    const shipTo = uPrefObj?.shipTo || countryId;

    setShipToCountry(shipTo);
  }, [countryId]);

  const { data, loading, error } = useQuery(GET_ADDRESS_QUERY, {
    skip: !isEdit,
    variables: { id: addressId },
  });

  const mutationSettings = {
    refetchQueries: ['GetAddressPageData'],
    onCompleted: () => {
      document.dispatchEvent(new CustomEvent(EVENTS.updated));
    },
  };

  const [addAddress] = useMutation(ADD_ADDRESS_MUTATION, mutationSettings);

  const [updateAddress] = useMutation(UPDATE_ADDRESS_MUTATION, mutationSettings);

  const [removeAddress] = useMutation(REMOVE_ADDRESS_MUTATION, mutationSettings);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  // Handlers
  const handleOnAddAddress = async (addressInput) => (
    addAddress({ variables: { address: addressInput } })
  );

  const handleOnUpdateAddress = async (addressInput) => updateAddress({
    variables: { id: addressId, address: addressInput },
  });

  const handleOnRemove = async () => removeAddress({ variables: { id: addressId } });

  const handleOnSubmit = async (addressInput) => {
    if (!isEdit) return handleOnAddAddress(addressInput);

    return handleOnUpdateAddress(addressInput);
  };

  const heading = !isEdit ? textFor?.modalAddHeading : textFor?.modalEditHeading;

  const closeLabel = !isEdit ? textFor?.addAddressCloseLabel : textFor?.editAddressCloseLabel;

  const renderHeader = () => heading && (
    <span className="h2">
      <TmntText tmnt={heading} />
    </span>
  );

  return (
    <Modal
      id="address-modal"
      isOpen={isOpen}
      onClose={onClose}
      heading={renderHeader()}
      closeButtonLabel={closeLabel.value}
      closeButtonKey={closeLabel.key}
    >
      <AddressForm
        address={data?.address}
        countryId={shipToCountry}
        onClose={onClose}
        onSubmit={handleOnSubmit}
        onRemove={handleOnRemove}
      />
    </Modal>
  );
}

AddressModal.propTypes = propTypes;
AddressModal.defaultProps = defaultProps;
