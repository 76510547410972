import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { textPairFragmentStr } from '../../../query/fragmentStrings';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';
import TmntText from '../Text/TmntText';

const GET_PASSWORD_STRENGTH = gql`
  query GetPasswordStrengthText {
    textFor {
      passwordStrengthTitle: pair(pairKey: "passwordStrengthTitle") { ...textPair }
      passwordStrengthPoor: pair(pairKey: "passwordStrengthPoor") { ...textPair }
      passwordStrengthGood: pair(pairKey: "passwordStrengthGood") { ...textPair }
      passwordStrengthStrong: pair(pairKey: "passwordStrengthStrong") { ...textPair }
    }
  }

  ${textPairFragmentStr}
`;

const propTypes = {
  password: PropTypes.string.isRequired,
};

export default function PasswordStrengthMeter({ password }) {
  const [strength, setStrength] = useState(undefined);

  function evaluateStrength(passwordVal) {
    if (!passwordVal) return 'POOR';

    const hasNumbers = (/\d/.test(passwordVal));
    const hasLowercase = (/[a-z]/.test(passwordVal));
    const hasUppercase = (/[A-Z]/.test(passwordVal));
    const hasSpecialChars = (/[^A-Za-z0-9]/.test(passwordVal));

    const minReqMet = (passwordVal.length >= 8 && hasNumbers && (hasLowercase || hasUppercase));
    const strongReqMet = (
      passwordVal.length >= 12
      && hasNumbers
      && hasLowercase
      && hasUppercase
      && hasSpecialChars
    );

    if (strongReqMet) return 'STRONG';
    if (minReqMet) return 'GOOD';

    return 'POOR';
  }

  const { data, loading, error } = useQuery(GET_PASSWORD_STRENGTH);

  useEffect(() => {
    setStrength(evaluateStrength(password));
  }, [password]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;

  const getStrengthText = (strengthVal) => {
    switch (strengthVal) {
      case 'GOOD':
        return textFor?.passwordStrengthGood;
      case 'STRONG':
        return textFor?.passwordStrengthStrong;
      case 'POOR':
      default:
        return textFor?.passwordStrengthPoor;
    }
  };

  return (
    <div className="pw-strength" data-pwstrength={strength} data-testid="pw-strength">
      <div className="pw-strength__bar">
        <span className="pw-strength__bar-fill" />
      </div>
      <div className="pw-strength__label">
        <p>
          <TmntText tmnt={textFor?.passwordStrengthTitle} />
          :
          <span className="pw-strength__score">
            {' '}
            <TmntText tmnt={getStrengthText(strength)} />
          </span>
        </p>
      </div>
    </div>
  );
}

PasswordStrengthMeter.propTypes = propTypes;
